// @generated by protoc-gen-connect-es v1.4.0 with parameter "target=ts"
// @generated from file user/v1/user_api.proto (package user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetProfileRequest, GetProfileResponse, ListAllUsersRequest, ListAllUsersResponse, RequestAccountDeleteRequest, RequestAccountDeleteResponse, UpdateProfileRequest, UpdateProfileResponse } from "./user_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * UserAPI service
 *
 * @generated from service user.v1.UserAPI
 */
export const UserAPI = {
  typeName: "user.v1.UserAPI",
  methods: {
    /**
     * @generated from rpc user.v1.UserAPI.GetProfile
     */
    getProfile: {
      name: "GetProfile",
      I: GetProfileRequest,
      O: GetProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc user.v1.UserAPI.UpdateProfile
     */
    updateProfile: {
      name: "UpdateProfile",
      I: UpdateProfileRequest,
      O: UpdateProfileResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc user.v1.UserAPI.ListAllUsers
     */
    listAllUsers: {
      name: "ListAllUsers",
      I: ListAllUsersRequest,
      O: ListAllUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc user.v1.UserAPI.RequestAccountDelete
     */
    requestAccountDelete: {
      name: "RequestAccountDelete",
      I: RequestAccountDeleteRequest,
      O: RequestAccountDeleteResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

