import { BrowserRouter } from "react-router-dom";
import Screens from "./Screens";
import { AuthProvider } from "hooks";

export const Main = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Screens />
      </AuthProvider>
    </BrowserRouter>
  );
};
