import { useToast } from "@chakra-ui/react";
import { authAPIClient } from "clients";
import { useAuthContext } from "./useAuth";

export const useLogout = () => {
  const toast = useToast();
  const { logout } = useAuthContext();

  return async () => {
    try {
      await authAPIClient.logout({});
      toast({
        title: "Logged out!",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      logout();
    } catch (error) {
      toast({
        title: "Error logging you out!",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "bottom",
      });
    }
  };
};
