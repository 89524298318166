import { Box, Center, Stack, Text, VStack } from "@chakra-ui/react";
import { ConnectError } from "@connectrpc/connect";

interface Props {
  size?: number;
  color?: string;
  error: ConnectError | null;
}

export const DisplayError = ({ size = 36, color = "red", error }: Props) => {
  return (
    <VStack justify="center" align="stretch" h="77vh">
      <Box w="100%">
        <Stack>
          <Center>
            <Text
              py={2}
              style={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: size,
              }}
              color={color}
            >
              An error has occurred: {error?.message}
            </Text>
          </Center>
        </Stack>
      </Box>
    </VStack>
  );
};
